export default {
  root: {
    textAlign: 'center',
  },
  sold: {
    color: '#A0A0A0',
    paddingRight: '0.5em',
    textDecoration: 'line-through',
  },
};
